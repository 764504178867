import { defineStore } from 'pinia'
import { useWebshopsStore } from './webshops'
import { useAnalyticsStore } from './analytics'

const defaultWebshopFilters = ['trafficData', 'trafficGrowth', 'shippingProvider', 'companyName', 'estimatedActivity', 'employees', 'zipCode', 'deliveryCountries']

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      user_id: '',
      user_name: '',
      email: '',
      admin: false,
      modules: [],
      pictureCache: null,
      customer: '',
      default_view: null,
      providers: [],
      notifications: [],
      webshopFilters: [],
      webshopColumns: [],
      loading: true,
      providerOptions: []
    }
  },

  actions: {
    async getUser() {
      const webshop = useWebshopsStore()
      const analytics = useAnalyticsStore()

      const response = await handleGet({ url: `/frontend/user`, errMsg: 'Failed to get user information' })
      if (!response?.data) {
        return
      }

      const values = response.data

      // const filters = webshopFilters.map(x => x.id)
      const filters = values.webshop_filters || defaultWebshopFilters

      // const columns = defaultWebshopColumns
      const columns = values.webshop_columns || defaultWebshopColumns

      this.user_id = values.user_id
      this.user_name = values.user_name
      this.email = values.email
      this.customer = values.customer_name
      this.pictureCache = values.picture_cache
      this.providers = values.shipping_providers?.split(", ") || []
      this.admin = values.admin
      this.modules = values.modules
      this.providerOptions = values.provider_options || []

      webshop.country_code = values.country_code
      analytics.countryCode = values.country_code

      this.webshopFilters = filters.map(f => { return { ...getWebshopFilters(values.modules, values.country_code).find(x => x.id === f) } }).filter(obj => Object.keys(obj).length)
      this.webshopColumns = columns.map(c => { return { ...getWebshopColumns(values.modules, values.country_code).find(s => s.key === c.key) } }).filter(obj => Object.keys(obj).length)

      initializeTracking(this.customer, this.email)

      useBugsnag().setUser(this.user_id, this.email, this.user_name)

      this.loading = false
    },

    async uploadPicture(picture) {
      await handlePost({
        url: `/frontend/user/picture`,
        body: {
          base_64_str: picture
        },
        errMsg: 'Failed to upload picture',
        successMsg: 'Profile picture updated'
      })

      this.pictureCache += 1
    },

    async deletePicture() {
      await handlePost({
        url: `/frontend/user/picture/delete`,
        body: {},
        errMsg: 'Failed to remove picture'
      })

      this.pictureCache += 1
    },

    async getNotifications() {
      try {
        const response = await handleGet({ url: `/frontend/user/notifications` })

        this.notifications = response.data
      } catch (error) {
        console.log(error)
      }
    },

    async clickNotification(notification_id) {
      this.notifications = this.notifications.map(n => {
        return n.notification_id === notification_id ? { ...n, clicked: 1 } : n
      })

      await handlePost({
        url: `/frontend/user/notifications/read`,
        body: {
          notificationId: notification_id
        }
      })
    },

    async readAllNotifications() {
      this.notifications = this.notifications.map(n => {
        return { ...n, clicked: 1 }
      })

      await handlePost({ url: `/frontend/user/notifications/read/all` })
    },

    async updateColumns() {
      await handlePost({
        url: `/frontend/user/settings/columns`,
        body: {
          columns: this.webshopColumns.map(x => {
            return {
              key: x.key
            }
          })
        },
        errMsg: "Could not save column settings"
      })
    },

    async updateFilterSettings() {
      await handlePost({
        url: `/frontend/user/settings/filters`,
        body: {
          filters: this.webshopFilters.map(filter => filter.id)
        },
        errMsg: 'Could not save filters'
      })
    },

    async updateDefaultCountry(country) {
      await handlePost({
        url: `/frontend/user/settings/country`,
        body: {
          countryCode: country
        },
      })
    },

    checkoutPositions(shipping_rows) {
      try {
        const rows = shipping_rows.split(", ")
        const positions = []

        rows.forEach((row, i) => {
          if (this.providers.includes(row.split(";")[0])) {
            positions.push(i + 1)
          }
        })

        return positions
      } catch (ex) {
        return []
      }
    },

    lostPosition(lostProviders, firstProvider, firstSinceDate) {
      if (this.providers.includes(firstProvider)) {
        return [{ text: 'Won', date: firstSinceDate }]
      }

      try {
        const formatted = Object.entries(lostProviders).map(([key, value]) => {
          return { name: key, date: value }
        })

        formatted.sort((a, b) => new Date(b.date) - new Date(a.date))

        const index = formatted.findIndex(x => this.providers.includes(x.name))
        if (index > -1) {
          return [{ text: 'Lost', date: formatted[index].date }]
        }

        return [{}]
      } catch (ex) {
        return [{}]
      }
    },
  }
})
