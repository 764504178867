import { defineStore } from 'pinia'

export const useAnalyticsStore = defineStore('analytics', {
  state: () => {
    return {
      overview: {},
      delivery: [],
      webshop: [],
      countryCode: '',
      loading: false,
    }
  },

  actions: {
    async getOverviewForProvider(provider) {
      if (this.overview[provider]) {
        return
      }

      this.loading = true

      try {
        const response = await handleGet({
          url: `/frontend/analytics/your_overview?countryCode=${this.countryCode}&shippingProvider=${provider}`
        })

        this.overview[provider] = response.data
      } catch (ex) { }

      this.loading = false
    },

    async getDeliveryData() {
      if (this.delivery.length > 0) {
        return
      }

      this.loading = true

      try {
        const response = await handleGet({
          url: `/frontend/analytics/delivery?countryCode=${this.countryCode}`
        })

        this.delivery = response.data
      } catch (ex) { }

      this.loading = false
    },

    async getWebshopData() {
      if (this.webshop.length > 0) {
        return
      }

      this.loading = true

      try {
        const response = await handleGet({
          url: `/frontend/analytics/webshops?countryCode=${this.countryCode}`
        })

        this.webshop = response.data
      } catch (ex) { }

      this.loading = false
    }
  }
})
