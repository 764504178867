export const getFilterCategories = modules => {
    let filterCategories = [
        {value: "0", text: "Webshop", icon: "mdi-cart-outline"},
        {value: "1", text: "Delivery", icon: "mdi-truck-delivery-outline"},
        {value: "2", text: "Company", icon: "mdi-domain"},
        {value: "3", text: "Financial", icon: "mdi-finance"},
    ]

    return filterCategories.sort((a, b) => a.value - b.value)
}

export const FilterTypes = {
    AUTOCOMPLETE: "autocomplete",
    SELECT: "select",
    INPUTRANGE: "inputRange",
    SLIDER: "slider",
    SEARCH: "search",
    CUSTOM: "custom",
    COMBOSLIDER: "comboSlider",
    COMBOPROVIDER: "comboProvider",
    POSITIONCHANGE: "positionChange",
    CHECKBOX: "checkbox",
}

export const filterIds = {
    domainIdList: [],
    userIdList: [],
    leadStatusList: [],
    webshopUrlText: "",
    companyNameList: [],
    companyNumberList: [],
    companyAddressList: [],
    companyFoundedRange: [],
    employeesMappedList: [],
    revenueRange: [],
    revenueGrowthRange: [],
    grossprofitRange: [],
    resultRange: [],
    municipalityList: [],
    zipCodeRange: [],
    excludeZipCodeList: [],
    industryList: [],
    webshopUrlList: [],
    estActivityList: [],
    numProductsMappedList: [],
    numCategoriesMappedList: [],
    taSystemsList: [],
    paymentSystemsList: [],
    checkoutSystemsList: [],
    threeplSystemsList: [],
    otherSystemsList: [],
    returnSystemsList: [],
    platformList: [],
    ecommerceCategoryList: [],
    shippingProviderList: [],
    excludeShippingProviderList: [],
    shippingProviderListTerms: [],
    excludeshippingProviderListTerms: [],
    deliveryCountriesList: [],
    excludeDeliveryCountriesList: [],
    shippingMethodList: [],
    thresholdForDiscount: [],
    shippingText: "",
    lostFirstPositionProviders: [],
    lostFirstPositionDateFrom: null,
    shippingPositionProvider: "",
    bestShippingPositionProvider: null,
    bestShippingPositionProviderRange: [],
    deliveryAddress: "",
    includeMethodShippingProvider: null,
    excludeMethodShippingProvider: null,
    shippingProviderMethod: null,
    returnAddressList: [],
    currency: window.localStorage.getItem("currency") || "EUR",
    trafficDataMappedList: [],
    trafficGrowthRange: [],
    hasOrgNumber: null,
}

let excludeMapping = {
    BE: [
        "returnAddress",
        "industry",
        "companyFounded",
    ],
    CH: [
        "trafficGrowth",
        "trafficData",
        "returnAddress",
        "industry",
        "employees",
        "companyFounded",
        "result",
        "grossProfit",
        "revenueGrowth",
        "revenue",
    ],
    DK: [],
    EE: [
        "trafficGrowth",
        "returnAddress",
        "municipality",
        "employees",
        "grossProfit",
        "revenueGrowth",
    ],
    FI: [],
    IT: [
        "trafficGrowth",
        "trafficData",
        "providerShippingMethod",
        "shippingMethod",
        "shippingProviderChange",
        "industry",
        "zipCode",
        "municipality",
        "employees",
        "companyFounded",
        "companyAddress",
        "companyName",
        "result",
        "grossProfit",
        "revenueGrowth",
        "revenue",
    ],
    LT: [
        "trafficGrowth",
        "returnAddress",
        "industry",
        "municipality",
        "employees",
        "grossProfit",
        "revenueGrowth",
    ],
    LV: [
        "trafficGrowth",
        "returnAddress",
        "industry",
        "municipality",
        "employees",
        "grossProfit",
        "revenueGrowth",
    ],
    NL: [
        "trafficGrowth",
        "industry",
        "employees",
        "companyFounded",
        "result",
        "grossProfit",
        "revenueGrowth",
        "revenue",
    ],
    NO: [],
    SE: [],
    SK: [
        "trafficGrowth",
        "trafficData",
        "returnAddress",
        "industry",
        "employees",
        "companyFounded",
        "result",
        "grossProfit",
        "revenueGrowth",
        "revenue",
    ],
}


export const getWebshopFilters = (modules, countryCode) => {
    let filters = [
        {
            id: "webshopUrl",
            category: "0",
            type: FilterTypes.AUTOCOMPLETE,
            name: "Webshop name",
            shortName: "Webshop",
            description: "",
            placeholder: "Select webshop",
            activeFilters: "webshopUrlList",
            filterKey: "webshopUrls",
            fetchAction: "fetchDomains",
        },
        {
            id: "estimatedActivity",
            category: "0",
            type: FilterTypes.SELECT,
            name: "Technological investments",
            shortName: "Activity",
            description:
                "Filter based on a webshops invesments in technology. There is a strong correlation between high investments and high consumer traffic.",
            placeholder: "Select activity",
            activeFilters: "estActivityList",
            filterKey: "activities",
        },
        {
            id: "numberOfProducts",
            category: "0",
            type: FilterTypes.SELECT,
            name: "Number of products",
            shortName: "Products",
            description:
                "Filter based on number of products that are for sale in a webshop.",
            placeholder: "Select number",
            activeFilters: "numProductsMappedList",
            filterKey: "numProductsRanges",
        },
        {
            id: "numberOfCategories",
            category: "0",
            type: FilterTypes.SELECT,
            name: "Number of categories",
            shortName: "Categories",
            description:
                "Filter based on number of product categories available in a webshop.",
            placeholder: "Select number",
            activeFilters: "numCategoriesMappedList",
            filterKey: "numCategoriesRanges",
        },
        {
            id: "taSystem",
            category: "0",
            type: FilterTypes.AUTOCOMPLETE,
            name: "TA system",
            shortName: "TA system",
            description: "Filter Transportation Systems used by delivery providers.",
            placeholder: "Select TA system",
            activeFilters: "taSystemsList",
            filterKey: "taSystems",
            fetchAction: "fetchSoftwareSystems",
        },
        {
            id: "checkoutSoftware",
            category: "0",
            type: FilterTypes.AUTOCOMPLETE,
            name: "Checkout system",
            shortName: "Checkout system",
            description: "Software used in the checkout.",
            placeholder: "Select checkout software",
            activeFilters: "checkoutSystemsList",
            filterKey: "checkoutSystems",
            fetchAction: "fetchSoftwareSystems",
        },
        {
            id: "paymentSoftware",
            category: "0",
            type: FilterTypes.AUTOCOMPLETE,
            name: "Payment system",
            shortName: "Payment system",
            description: "Payment system used.",
            placeholder: "Select payment",
            activeFilters: "paymentSystemsList",
            filterKey: "paymentSystems",
            fetchAction: "fetchSoftwareSystems",
        },
        {
            id: "returnSoftware",
            category: "0",
            type: FilterTypes.AUTOCOMPLETE,
            name: "Return system",
            shortName: "Return system",
            description: "Return system used.",
            placeholder: "Select return system",
            activeFilters: "returnSystemsList",
            filterKey: "returnSystems",
            fetchAction: "fetchSoftwareSystems",
        },
        {
            id: "3PLSoftware",
            category: "0",
            type: FilterTypes.AUTOCOMPLETE,
            name: "3PL",
            shortName: "3PL",
            description: "3PL system used.",
            placeholder: "Select 3PL",
            activeFilters: "threeplSystemsList",
            filterKey: "ThreePLSystems",
            fetchAction: "fetchSoftwareSystems",
        },
        {
            id: "OtherSoftware",
            category: "0",
            type: FilterTypes.AUTOCOMPLETE,
            name: "Other system",
            shortName: "other system",
            description: "Other system used.",
            placeholder: "Select other system",
            activeFilters: "otherSystemsList",
            filterKey: "otherSystems",
            fetchAction: "fetchSoftwareSystems",
        },
        {
            id: "platform",
            category: "0",
            type: FilterTypes.AUTOCOMPLETE,
            name: "Webshop platform",
            shortName: "Platform",
            description:
                "Filter based on the e-commerce platform the webshop is using for hosting.",
            placeholder: "Select platform",
            activeFilters: "platformList",
            filterKey: "platforms",
            fetchAction: "fetchPlatforms",
        },
        {
            id: "tembiCategory",
            category: "0",
            type: FilterTypes.AUTOCOMPLETE,
            name: "Product category",
            shortName: "Category",
            description: "Filter based on identified product categories.",
            placeholder: "Select category",
            activeFilters: "ecommerceCategoryList",
            filterKey: "tembiCategories",
            fetchAction: "fetchTembiCategories",
            selectAll: true,
        },

        {
            id: "freeDeliveryThreshold",
            category: "1",
            type: FilterTypes.SLIDER,
            name: "Free delivery threshold",
            shortName: "Threshold",
            description:
                "Filter webshops based on the threshold free delivery is offered.",
            placeholder: "Select threshold",
            activeFilters: "thresholdForDiscount",
            filterKey: "freeDeliveryRange",
        },
        {
            id: "returnAddress",
            category: "1",
            type: FilterTypes.AUTOCOMPLETE,
            name: "Parcel return address",
            shortName: "Return address",
            description: "Parcels' return address mentioned on the webshop.",
            placeholder: "Search address",
            activeFilters: "returnAddressList",
            filterKey: "returnAddress",
            fetchAction: "fetchReturnAddress",
        },
        {
            id: "deliveryCountries",
            category: "1",
            type: FilterTypes.CUSTOM,
            name: "Delivery & shipping markets",
            shortName: "Country",
            description: "Filter based on available markets for shipping.",
            placeholder: "Select region/country",
            activeFilters: "deliveryCountriesList",
            filterKey: "deliveryCountries",
        },
        {
            id: "excludeDeliveryCountries",
            category: null,
            type: FilterTypes.CUSTOM,
            name: "Exclude Delivery & shipping markets",
            shortName: "Country",
            description: "Filter based on available markets for shipping.",
            placeholder: "Select region/country",
            activeFilters: "excludeDeliveryCountriesList",
            filterKey: "deliveryCountries",
        },

        {
            id: "companyName",
            category: "2",
            type: FilterTypes.AUTOCOMPLETE,
            name: "Company name",
            shortName: "Company",
            description: "",
            placeholder: "Search company",
            activeFilters: "companyNameList",
            filterKey: "companyNames",
            fetchAction: "fetchCompanies",
        },
        {
            id: "hasCompanyNumber",
            category: "2",
            type: FilterTypes.CHECKBOX,
            name: "Organisation number",
            shortName: "Has org nr.",
            description: "Find webshops with Business identificaiton numbers.",
            label: "Has organisation number",
            activeFilters: "hasOrgNumber",
        },
        {
            id: "companyAddress",
            category: "2",
            type: FilterTypes.AUTOCOMPLETE,
            name: "Address",
            shortName: "Address",
            description: "Search for webshops at a specific address.",
            placeholder: "Search address",
            activeFilters: "companyAddressList",
            filterKey: "companyAddress",
            fetchAction: "fetchCompanyAddress",
        },
        {
            id: "companyFounded",
            category: "2",
            type: FilterTypes.SLIDER,
            name: "Registration date",
            shortName: "Registration date",
            description:
                "Filter based on number of years a company or webshop has been on the market.",
            activeFilters: "companyFoundedRange",
            filterKey: "companyFoundedRanges",
        },
        {
            id: "employees",
            category: "2",
            type: FilterTypes.SELECT,
            name: "Number of Employees",
            shortName: "Employees",
            description: "",
            placeholder: "Select employees",
            activeFilters: "employeesMappedList",
            filterKey: "employeeRanges",
        },
        {
            id: "municipality",
            category: "2",
            type: FilterTypes.AUTOCOMPLETE,
            name: "Municipality",
            shortName: "Municipality",
            description: "",
            placeholder: "Select municipality",
            activeFilters: "municipalityList",
            filterKey: "municipalities",
            fetchAction: "fetchMunicipalities",
        },
        {
            id: "zipCode",
            category: "2",
            type: FilterTypes.INPUTRANGE,
            name: "Zip code",
            shortName: "Zip",
            description: "",
            activeFilters: "zipCodeRange",
            filterKey: "zipCodes",
            fetchAction: "fetchZipCodes",
        },
        {
            id: "industry",
            category: "2",
            type: FilterTypes.AUTOCOMPLETE,
            name: "Industry code",
            shortName: "Industry",
            description: "Filter webshops by industry classification.",
            placeholder: "Select industry",
            activeFilters: "industryList",
            filterKey: "industries",
            fetchAction: "fetchIndustries",
        },

        {
            id: "revenue",
            category: "3",
            type: FilterTypes.INPUTRANGE,
            name: "Company revenue",
            shortName: "Revenue",
            description: "Filter based on a company's revenue.",
            activeFilters: "revenueRange",
            filterKey: "revenueRanges",
            currency: true,
        },
        {
            id: "revenueGrowth",
            category: "3",
            type: FilterTypes.SLIDER,
            name: "Revenue growth",
            shortName: "Revenue growth",
            description:
                "Filter based on revenue growth data from the last three years.",
            activeFilters: "revenueGrowthRange",
            filterKey: "revenueGrowthRanges",
        },
        {
            id: "grossProfit",
            category: "3",
            type: FilterTypes.INPUTRANGE,
            name: "Gross profit",
            shortName: "Gross",
            description: "Filter based on latest publically available gross profit.",
            activeFilters: "grossprofitRange",
            filterKey: "grossprofitRanges",
            currency: true,
        },
        {
            id: "result",
            category: "3",
            type: FilterTypes.INPUTRANGE,
            name: "Financial result",
            shortName: "Result",
            description:
                "Filter based on latest publically available financial result.",
            activeFilters: "resultRange",
            filterKey: "resultRanges",
            currency: true,
        },
    ]

    if (modules.includes("similarweb")) {
        filters = filters.concat([
            {
                id: "trafficData",
                category: "0",
                type: FilterTypes.SELECT,
                name: "Traffic data",
                shortName: "traffic",
                description: "Filter webshops by similarweb traffic.",
                placeholder: "Select traffic",
                activeFilters: "trafficDataMappedList",
                filterKey: "trafficRanges",
            },
            {
                id: "trafficGrowth",
                category: "0",
                type: FilterTypes.SLIDER,
                name: "Traffic growth",
                shortName: "traffic growth",
                description: "Webshop traffic growth the last three months.",
                placeholder: "Select traffic",
                activeFilters: "trafficGrowthRange",
                filterKey: "trafficGrowthRanges",
            },
        ])
    }

    if (modules.includes("checkout")) {
        filters = filters.concat([
            {
                id: "shippingProvider",
                category: "1",
                type: FilterTypes.CUSTOM,
                name: "Delivery Provider",
                shortName: "Include Provider",
                description: "Filter webshops by which Delivery Provider(s) they use.",
                placeholder: "Select provider",
                filterKey: "shippingProviders",
                activeFilters: "shippingProviderList",
                fetchAction: "fetchShippingProviders",
            },
            {
                id: "shippingProviderChange",
                category: "1",
                type: FilterTypes.POSITIONCHANGE,
                name: "Lost first position",
                shortName: "Provider change",
                description:
                    "Last time a shipping provider lost first position at the checkout.",
                activeFilters: "lostFirstPositionDateFrom",
                filterKey: "",
            },
            {
                id: "excludeProvider",
                category: null,
                type: FilterTypes.CUSTOM,
                name: "Exclude provider",
                shortName: "Exclude Provider",
                description: "",
                placeholder: "Select provider",
                filterKey: "shippingProviders",
                activeFilters: "excludeShippingProviderList",
                fetchAction: "fetchShippingProviders",
            },
            {
                id: "excludeProviderTerms",
                category: null,
                type: FilterTypes.CUSTOM,
                name: "Exclude provider T&C",
                shortName: "Exclude Provider",
                description: "",
                placeholder: "Select provider",
                filterKey: "shippingProviders",
                activeFilters: "excludeshippingProviderListTerms",
                fetchAction: "fetchShippingProviders",
            },
            {
                id: "shippingMethod",
                category: "1",
                type: FilterTypes.SELECT,
                name: "Delivery option",
                shortName: "Method",
                description: "Filter based on delivery options for consumers.",
                placeholder: "Select method",
                activeFilters: "shippingMethodList",
                filterKey: "shippingMethodList",
            },
            {
                id: "shppingText",
                category: "1",
                type: FilterTypes.SEARCH,
                name: "Checkout free search",
                shortName: "Shipping text",
                description:
                    'Search for words i.e. "Express" in how delivery providers are displayed to consumers.',
                activeFilters: "shippingText",
            },
            {
                id: "bestShippingPosition",
                category: "1",
                type: FilterTypes.COMBOSLIDER,
                name: "Delivery Provider position",
                shortName: "Best position",
                description:
                    "Filter based on Delivery Provider and their position in delivery checkouts. You can either select a position or a range.",
                activeSliderFilters: "bestShippingPositionProviderRange",
                activeSelectFilters: "bestShippingPositionProvider",
                sliderFilterKey: "bestShippingPositionRange",
                selectFilterKey: "shippingProviders",
                placeholder: "Select provider",
            },
            {
                id: "providerShippingMethod",
                category: "1",
                type: FilterTypes.COMBOPROVIDER,
                name: "Provider shipping method",
                shortName: "Provider method",
                description:
                    "Available shipping method at the checkout for a specific provider",
                activeFilters: "shippingProviderMethod",
                filterKey: "shippingMethodList",
                placeholder: "Select method",
            },
        ])
    }

    return filters.filter(f => !excludeMapping[countryCode].includes(f.id))
}

export const savedFilterTypes = {
    FILTER: "filter",
    LIST: "list",
    WEBSHOP: "webshop",
}

export const leadStatusValues = [
    {
        text: "Open",
        background: "bg-yellow-lighten-5",
        color: "text-deep-orange-darken-4",
    },
    {
        text: "In progress",
        background: "bg-green-lighten-5",
        color: "text-green-darken-4",
    },
    {
        text: "Customer",
        background: "bg-blue-lighten-5",
        color: "text-deep-purple-darken-4",
    },
    {
        text: "Closed",
        background: "bg-grey-lighten-4",
        color: "text-grey-darken-4",
    },
    {
        text: "Unqualified",
        background: "bg-red-lighten-5",
        color: "text-red-darken-4",
    },
    {
        text: "Blacklisted",
        background: "bg-purple-lighten-5",
        color: "text-purple-darken-4",
    },
]

