import {defineStore} from 'pinia'

export const useWebshopsStore = defineStore('webshops', {
    state: () => {
        return {
            data: [],
            total: 0,
            nonce: 0,
            filters: {...filterIds},
            params: {
                pageSize: 25,
                pageNum: 1,
                orderBy: [],
            },
            notification_id: null,
            country_code: '',
            resetSavedListFiltersFlag: null,
            appliedSavedFilter: null,
            savedFilters: [],
            savedLists: [],
            monitor: [],
            selectAll: false,
            error: null,
            loading: true,
            loadingTotal: true
        }
    },

    actions: {
        filterBody() {
            const route = useRoute()

            return {
                ...Object.entries(this.params).reduce((a, [k, v]) => (!v || v.length < 1 ? a : (a[k] = v, a)), {}),
                filters: Object.entries(this.filters).reduce((a, [k, v]) => (!v || v.length < 1 ? a : (a[k] = v, a)), {}),
                countryCode: this.country_code,
                listId: route.params?.listId,
                notificationId: this.notification_id
            }
        },

        initialize() {
            this.getSavedFilters(true)
        },

        resetFilters() {
            this.$patch({
                filters: {
                    ...filterIds,
                    currency: window.localStorage.getItem('currency') || 'EUR'
                },
                appliedSavedFilter: null,
                selectAll: false,
                notification_id: null
            })
        },

        async getData(resetPage) {
            this.nonce++
            const identifier = this.nonce

            if (resetPage && this.params.pageNum !== 1) {
                this.params.pageNum = 1 // Triggers new getData
                return
            }

            try {
                this.loading = true

                const response = await handlePost({
                    url: '/frontend/domains/list',
                    body: this.filterBody(),
                    errMsg: 'Failed to receive webshop data'
                })

                if (identifier === this.nonce) {
                    this.data = response.data
                }
            } catch (error) {
            } finally {
                this.loading = false
            }
        },

        async getTotal() {
            try {
                this.loadingTotal = true

                const response = await handlePost({
                    url: '/frontend/domains/count',
                    body: this.filterBody()
                })

                this.total = response.data.total
            } catch (ex) {
            } finally {
                this.loadingTotal = false
            }
        },

        removeFilter(filter, name, type) {
            if ([FilterTypes.AUTOCOMPLETE, FilterTypes.SELECT, FilterTypes.CUSTOM].includes(type)) {
                this.filters[filter] = this.filters[filter].filter(x => x !== name)
            } else if ([FilterTypes.INPUTRANGE, FilterTypes.SLIDER].includes(type)) {
                this.filters[filter] = []
            } else if ([FilterTypes.SEARCH].includes(type)) {
                this.filters[filter] = ""
            } else if (type === FilterTypes.COMBOPROVIDER) {
                this.filters[filter] = ""

                if (name === 'shippingProviderMethod') {
                    this.$patch({
                        filters: {
                            shippingProviderMethod: null,
                            includeMethodShippingProvider: null,
                            excludeMethodShippingProvider: null
                        }
                    })
                }
            } else if (type === FilterTypes.COMBOSLIDER) {
                this.$patch({
                    filters: {
                        bestShippingPositionProvider: null,
                        bestShippingPositionProviderRange: []
                    }
                })
            } else if (type === FilterTypes.POSITIONCHANGE) {
                this.$patch({
                    filters: {
                        lostFirstPositionDateFrom: null,
                        lostFirstPositionProviders: []
                    }
                })
            } else if (type === FilterTypes.CHECKBOX) {
                this.filters[filter] = null
            }
        },

        async getSavedFilters(applyDefault) {
            try {
                const response = await handleGet({url: `/frontend/filters/saved`})

                this.savedFilters = response.data

                // Todo: This is a workaround to avoid applying default filters on lists, but it should be handled in a better way.
                const route = useRoute();
                const {path} = route
                const isNotLists = !path.includes('/lists')


                if (applyDefault && isNotLists) {
                    this.appliedSavedFilter = this.savedFilters.find(x => x.default && x.country_code === this.country_code)
                    if (this.appliedSavedFilter) {
                        this.$patch({
                            filters: {
                                ...this.filters,
                                ...this.appliedSavedFilter.filter
                            }
                        })
                    } else {
                        this.getTotal()
                    }
                }
                // else we just remove the filters

            } catch (ex) {
            }
        },

        async saveFilters(filterName, notificationSettings, defaultFilter) {
            const filters = Object.entries(this.filters).reduce((a, [k, v]) => (!v || v.length < 1 ? a : (a[k] = v, a)), {})

            await handlePost({
                url: '/frontend/filters/save',
                body: {
                    filterName,
                    filter: filters,
                    notificationSettings,
                    defaultFilter: defaultFilter,
                    countryCode: this.country_code
                },
                errMsg: 'Could not save filters',
                successMsg: `${filterName} was saved as a filter`
            })

            this.getSavedFilters()
        },

        async updateFilter(filterValues, refresh = true, successMsg) {
            await handlePost({
                url: '/frontend/filters/saved/update',
                body: {
                    filterId: filterValues.filter_id,
                    filterName: filterValues.filter_name,
                    filter: filterValues.filter,
                    notificationSettings: filterValues.notification_settings,
                    countryCode: filterValues.country_code,
                    defaultFilter: filterValues.default_filter,
                    removeDefaultFilter: filterValues.remove_default_filter
                },
                errMsg: 'Could not update filter',
                successMsg: successMsg
            })

            if (refresh) {
                await this.getSavedFilters()
            }
        },

        async deleteFilter(filterId, errMsg, successMsg) {
            await handlePost({
                url: '/frontend/filters/saved/delete',
                body: {
                    filterId
                },
                errMsg: errMsg,
                successMsg: successMsg
            })

            this.getSavedFilters()
            this.resetFilters()
        },

        async monitorWebshop(domainId, domainName, notificationSettings, successMsg) {
            await handlePost({
                url: '/frontend/domains/monitor',
                body: {
                    domainId,
                    domainName,
                    notificationSettings
                },
                errMsg: 'Could not monitor webshop',
                successMsg
            })
        },


        async stopMonitor(domainId) {
            try {
                await handlePost({
                    url: '/frontend/domains/stopmonitor',
                    body: {
                        domainId,
                    },
                    errMsg: 'Could not stop monitoring'
                })
            } catch (ex) {
                console.log(ex)
            }
        },

        async assignDomain(domainIds, userId) {
            this.data = this.data.map(x => {
                return domainIds.includes(x.domain_id) ? {...x, user_id: userId !== 'unassign' ? userId : null} : x
            })

            await handlePost({
                url: userId === 'unassign' ? `/frontend/domains/unassign` : `/frontend/domains/assign`,
                body: {
                    domainIds,
                    userId
                },
                errMsg: 'Failed to assign webshops',
                successMsg: `${domainIds.length} webshops was ${userId == 'unassign' ? 'unassigned' : 'assigned'}`
            })
        },

        async updateStatus(domainIds, status) {
            this.data = this.data.map(x => {
                return domainIds.includes(x.domain_id) ? {...x, status} : x
            })

            await handlePost({
                url: `/frontend/domains/status`,
                body: {
                    domainIds,
                    status
                },
                errMsg: 'Failed to update status',
                successMsg: 'Status updated'
            })
        },

        async updateStatusByFilter(status) {
            this.data = this.data.map(x => {
                return {...x, status}
            })

            await handlePost({
                url: `/frontend/domains/status/filter`,
                body: {
                    status,
                    filters: this.filterBody()
                },
                errMsg: 'Failed to update status',
                successMsg: `Updated status on ${this.total} webshops`
            })
        },

        async assignDomainsByFilter(userId) {
            this.data = this.data.map(x => {
                return {...x, user_id: userId !== 'unassign' ? userId : null}
            })

            await handlePost({
                url: userId === 'unassign' ? `/frontend/domains/unassign/filter` : `/frontend/domains/assign/filter`,
                body: {
                    userId,
                    filters: this.filterBody()
                },
                errMsg: 'Failed to assign webshops',
                successMsg: `${this.total} webshops was ${userId == 'unassign' ? 'unassigned' : 'assigned'}`
            })
        },

        async exportDomains(domainIds) {
            await handleDownload({
                endpoint: `/frontend/domains/export/rows`,
                body: {
                    domainIds,
                },
                errMsg: 'Failed to export webshops',
                successMsg: `Exported ${Math.min(5000, domainIds.length)} domains`
            })
        },

        async exportDomainsByFilter() {
            await handleDownload({
                endpoint: `/frontend/domains/export/all`,
                body: {
                    filters: this.filterBody()
                },
                errMsg: 'Failed to export webshops',
                successMsg: `Exported ${Math.min(5000, this.total)} domains`
            })
        }
    }
})
