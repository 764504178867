export const getWebshopColumnsCategories = modules => {
  let columnCategories = [
    { value: "0", text: "Webshop", icon: "mdi-cart-outline" },
    { value: "1", text: "Delivery", icon: "mdi-truck-delivery-outline" },
    { value: "2", text: "Company", icon: "mdi-domain" },
    { value: "3", text: "Financial", icon: "mdi-finance" },
  ]

  return columnCategories.sort((a, b) => a.value - b.value)
}

export const getWebshopColumns = (modules, countryCode) => {
  let columns = [
    { category: "0", title: "Webshop", key: "domain", width: 200 },
    {
      category: "0",
      title: "Lead status",
      key: "lead_status",
      width: 170,
      sortable: false,
    },
    {
      category: "0",
      title: "Lead owner",
      key: "lead_owner",
      width: 220,
      sortable: false,
    },
    {
      category: "0",
      title: "Technological investments",
      key: "tech_spend_category",
      width: 220,
    },
    {
      category: "0",
      title: "TA system",
      key: "software_system_transportation_administration",
      width: 220,
    },
    {
      category: "0",
      title: "Payment system",
      key: "software_system_payment",
      width: 220,
    },
    {
      category: "0",
      title: "Return system",
      key: "software_system_return",
      width: 220,
    },
    {
      category: "0",
      title: "Checkout system",
      key: "software_system_checkout",
      width: 220,
    },
    {
      category: "0",
      title: "3PL",
      key: "software_system_third_party_logistics",
      width: 220,
    },
    {
      category: "0",
      title: "Other system",
      key: "software_system_other",
      width: 220,
    },
    { category: "0", title: "Webshop platform", key: "framework", width: 150 },
    { category: "0", title: "Webshop email", key: "contact_email" },
    { category: "0", title: "Language options", key: "languages", width: 220 },
    { category: "0", title: "Products (qty)", key: "n_products", width: 220 },
    {
      category: "0",
      title: "Categories (qty)",
      key: "n_categories",
      width: 220,
    },
    { category: "0", title: "Email", key: "email" },
    { category: "0", title: "Phone", key: "phoneNumbers_telephoneNumber" },
    {
      category: "0",
      title: "Product category",
      key: "ecommerce_category",
      width: 220,
    },
    {
      category: "0",
      title: "Parcel return address",
      key: "return_address",
      width: 220,
    },

    {
      category: "1",
      title: "Delivery & shipping markets",
      key: "countries",
      width: 220,
    },
    {
      category: "1",
      title: "Free delivery threshold",
      key: "free_shipping_threshold",
      width: 220,
      sortable: false,
    },

    { category: "2", title: "Key employee", key: "personRoles_1_name" },
    {
      category: "2",
      title: "Organisation number",
      key: "company_number",
      width: 220,
    },
    {
      category: "2",
      title: "Municipality",
      key: "location_municipality",
      width: 220,
    },
    { category: "2", title: "Industry", key: "naceCategories_1", width: 240 },
    {
      category: "2",
      title: "Registration date",
      key: "foundationDate",
      width: 220,
    },
    { category: "2", title: "Webshop owner", key: "name", width: 220 },
    { category: "2", title: "Employees", key: "numberOfEmployees", width: 170 },
    {
      category: "2",
      title: "Address",
      key: "postalAddress_addressLine",
      width: 220,
    },
    {
      category: "2",
      title: "Zip code",
      key: "postalAddress_zipCode",
      width: 120,
    },

    { category: "3", title: "Revenue", key: "revenue2022", width: 240 },
    {
      category: "3",
      title: "Gross profit",
      key: "grossprofit2022",
      width: 230,
    },
    { category: "3", title: "Financial result", key: "result2022", width: 230 },
  ]

  if (modules.includes("similarweb")) {
    columns = columns.concat([
      {
        category: "0",
        title: "Traffic (Similarweb)",
        key: "similarweb_num_visits",
        width: 240,
      },
      { category: "0", title: "Traffic history", key: "traffic_history" },
    ])
  }

  if (modules.includes("checkout")) {
    columns = columns.concat([
      {
        category: "1",
        title: "History",
        key: "history",
        width: 70,
        sortable: false,
      },
      {
        category: "1",
        title: "Delivery provider",
        key: "shipping_providers",
        width: 240,
      },
      {
        category: "1",
        title: "Checkout: Screenshot",
        key: "checkout_screenshot_url",
        sortable: false,
      },
      {
        category: "1",
        title: "Checkout: Your position",
        key: "checkout_positions",
        width: 220,
        sortable: false,
      },
      {
        category: "1",
        title: "Checkout: First position",
        key: "first_shipping_provider",
        width: 220,
      },
      {
        category: "1",
        title: "Checkout: Top positions",
        key: "shipping_rows",
        width: 220,
        sortable: false,
      },
      {
        category: "1",
        title: "Checkout: You at first position",
        key: "lost_first_position_date",
        width: 220,
        sortable: true,
      },
    ])
  }

  return columns
    .filter(col => !excludeMapping[countryCode].includes(col.key))
    .sort((a, b) => a.category - b.category)
}

export const fixedColumns = [
  { fixed: true, title: "", key: "selectBox", width: 48, sortable: false },
]

export const defaultWebshopColumns = [
  { key: "domain" },
  { key: "similarweb_num_visits" },
  { key: "traffic_history" },
  { key: "personRoles_1_name" },
  { key: "shipping_providers" },
  { key: "checkout_positions" },
  { key: "first_shipping_provider" },
  { key: "shipping_rows" },
  { key: "lost_first_position_date" },
  { key: "lead_status" },
  { key: "lead_owner" },
  { key: "history" },
  { key: "company_number" },
  { key: "tech_spend_category" },
  { key: "location_municipality" },
  { key: "software_system_transportation_administration" },
  { key: "software_system_payment" },
  { key: "software_system_checkout" },
  { key: "framework" },
  { key: "checkout_screenshot_url" },
  { key: "name" },
  { key: "naceCategories_1" },
  { key: "foundationDate" },
  { key: "contact_email" },
  { key: "postalAddress_zipCode" },
  { key: "countries" },
  { key: "languages" },
  { key: "n_products" },
  { key: "n_categories" },
  { key: "thresholdForDiscount" },
  { key: "numberOfEmployees" },
  { key: "email" },
  { key: "phoneNumbers_telephoneNumber" },
  { key: "postalAddress_addressLine" },
  { key: "return_address" },
  { key: "revenue2022" },
  { key: "grossprofit2022" },
  { key: "result2022" },
  { key: "ecommerce_category" },
]

let excludeMapping = {
  BE: [
    "email",
    "phoneNumbers_telephoneNumber",
    "foundationDate",
    "personRoles_1_name",
    "naceCategories_1",
  ],
  CH: [
    "similarweb_num_visits",
    "email",
    "phoneNumbers_telephoneNumber",
    "traffic_history",
    "numberOfEmployees",
    "foundationDate",
    "personRoles_1_name",
    "naceCategories_1",
    "result2022",
    "grossprofit2022",
    "revenue2022",
  ],
  DK: [],
  EE: ["location_municipality", "numberOfEmployees", "grossprofit2022"],
  FI: [],
  IT: [
    "similarweb_num_visits",
    "email",
    "phoneNumbers_telephoneNumber",
    "traffic_history",
    "history",
    "checkout_screenshot_url",
    "checkout_positions",
    "first_shipping_provider",
    "shipping_rows",
    "lost_first_position_date",
    "postalAddress_zipCode",
    "location_municipality",
    "numberOfEmployees",
    "foundationDate",
    "postalAddress_addressLine",
    "personRoles_1_name",
    "naceCategories_1",
    "result2022",
    "grossprofit2022",
    "revenue2022",
  ],
  LT: [
    "email",
    "phoneNumbers_telephoneNumber",
    "location_municipality",
    "numberOfEmployees",
    "personRoles_1_name",
    "naceCategories_1",
    "grossprofit2022",
  ],
  LV: [
    "email",
    "phoneNumbers_telephoneNumber",
    "location_municipality",
    "numberOfEmployees",
    "personRoles_1_name",
    "naceCategories_1",
    "grossprofit2022",
  ],
  NL: [
    "email",
    "phoneNumbers_telephoneNumber",
    "numberOfEmployees",
    "foundationDate",
    "personRoles_1_name",
    "naceCategories_1",
    "result2022",
    "grossprofit2022",
    "revenue2022",
  ],
  NO: [],
  SE: [],
  SK: [
    "similarweb_num_visits",
    "email",
    "phoneNumbers_telephoneNumber",
    "traffic_history",
    "numberOfEmployees",
    "foundationDate",
    "personRoles_1_name",
    "naceCategories_1",
    "result2022",
    "grossprofit2022",
    "revenue2022",
  ],
}
